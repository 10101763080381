import React from "react";
import ReactDOM from "react-dom";
import Modal from "react-modal";
import "./modal.scss";
import CloseBtn from "../../assets/images/close-btn.svg";

const customStyles = {
  content: {
    display: "flex",
    flexDirection: "column",
    akignItems: "center",
  },
};

export default function ModalInfo({ modalIsOpen, setIsOpen }) {
  let subtitle;

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = "#f00";
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <Modal
      isOpen={modalIsOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={customStyles.content}
      contentLabel="Example Modal"
    >
      <div className="wrapper">
        <img
          id="closeBtn"
          style={{ cursor: "pointer" }}
          src={CloseBtn}
          onClick={() => setIsOpen(false)}
        />
        <button className={"backButton"} onClick={() => setIsOpen(false)}>
          Back
        </button>
        <img style={{ width: "220px" }} src="/static/media/logo.107dfbad.svg" />
        <h2>
          <u>ACKNOWLEDGMENT OF REAL ESTATE REFERRAL INVOLVEMENT</u>
        </h2>
        <div className="modalContent">
          <p>1 </p>
          <p>
            The Client agrees and acknowledges that, AGM will be applying a
            credit of the Contract’s price as against the Contract (the
            “Credit”). The Client and the Agent will receive this Credit as a
            referral fee in the sum of total of eight percent (8%) of the
            Contract’s price, split into two equal parts between Client and
            Agent. The Client’s portion of the Credit, being four percent (4%)
            of the Contract’s price, shall be applied to the Client’s final
            balance owing under the Contract;
          </p>
        </div>
        <div className="modalContent">
          <p>2</p>
          <p>
            The Client further agrees and acknowledges that the Client is aware
            that the Agent and the Brokerage are not employees, agents,
            representatives, or otherwise related to AGM and that the Agent and
            the Brokerage, which term includes the assigns, beneficiaries,
            directors, employees, or other related parties of the Agent and the
            Brokerage, are not parties to the Contract. The Client further
            agrees and acknowledges that, in the event that a dispute arises
            between the Client and AGM, the Client will not be seeking any
            recourse from the Agent and the Brokerage; and
          </p>
        </div>
        <div className="modalContent">
          <p>3</p>
          <p>
            The Client acknowledges that they have carefully read this
            Acknowledgment, had the opportunity to seek the advice of a lawyer
            as to the nature and effect of this Acknowledgment, understand all
            of the terms of this Acknowledgment, and have executed this
            Acknowledgment voluntarily and with knowledge of the consequences
            thereof.
          </p>
        </div>
      </div>
    </Modal>
  );
}

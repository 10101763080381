import React, { useState } from "react";
import { Button, TextField, InputLabel } from "@material-ui/core";
import Box from "@mui/material/Box";
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import cls from "../../assets/Styles/stylesRefForm.scss";
import ModalInfo from "../Modal/ModalInfo";
import "./refarprogram.scss";

const IS_DEV = window.location.hostname === "localhost";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "470px",

    "& .MuiPaper-root": {
      overflow: "visible",
      marginTop: "-10px",
    },
    "& .MuiFormControl-root": {
      marginRight: "10px",
      paddingRight: "10px",
    },
    // "& .MuiInputLabel-formControl": {
    //   top: 0,
    //   left: 0,
    //   position: "absolute",
    //   transform: "translate(0, 17px) scale(1)",
    // },

    "& .MuiFormLabel-root.Mui-focused": {
      // display: "none",
      // top: "0",
      // color: "gray",
      // transition: "180ms",
      // top: 0,
      // left: 0,
      // position: "absolute",
      // fontSize: "small",
      // transform: "translate(0, 1px) scale(1)",
    },

    [theme.breakpoints.down("sm")]: {
      width: "270px",
    },
  },
  form_failed: {
    // border: "2px solid red",
    margin: "5px",
    color: "red",
  },
  form_success: {
    // border: "2px solid lightskyblue",
    margin: "5px",
  },
  menuStyle: {
    border: "1px solid black",
    borderRadius: "5%",
    backgroundColor: "lightgrey",
  },
}));

export default function ReferralAgents() {
  const { enqueueSnackbar } = useSnackbar();
  const mobile = window.innerWidth < 650;

  const [modalIsOpen, setIsOpen] = useState(false);
  const [checkBoxAgree, setCheckBox] = useState(false);
  const [showCheckBoxError, setCheckBoxError] = useState(false);

  const classes = useStyles();
  const [isDataSent, setSentData] = useState(false);

  const { errors, touched, values, handleChange, handleSubmit, isSubmitting } =
    useFormik({
      enableReinitialize: true,
      initialValues: {
        name: "",
        phone: "",
        email: "",
        referringEmail: "",
        accept: false,
      },
      validationSchema: Yup.object().shape({
        accept: Yup.bool().oneOf(
          [true],
          "You must accept the terms and conditions"
        ),
        name: Yup.string().required(),
        phone: Yup.string()
          .matches(/^\d+$/, "Only numbers")
          .length(10)
          .required(),
        email: Yup.string().email().required(),
        referringEmail: Yup.string().email().required(),
      }),
      onSubmit: async () => {
        if (IS_DEV) console.log("VALUES", { ...values });

        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const requestOptions = {
          method: "POST",
          headers: myHeaders,
          redirect: "follow",
          mode: "no-cors",
          body: JSON.stringify({ ...values }),
        };

        // const resp = await new Promise((resolve) => {
        //   fetch( "https://hooks.zapier.com/hooks/catch/2915270/b7y7iui/", requestOptions )
        //   .then((response) => response.text())
        //   .then(result => resolve(result))
        //   .catch((error) => console.log( 'error', error ));
        // });

        const resp = await new Promise((resolve) => {
          fetch(
            "https://hooks.zapier.com/hooks/catch/2915270/bnpx0wq/", requestOptions
          ).then((response) => response.text())
          .then((resp) => resolve(resp))
          .catch((error) => enqueueSnackbar("Something wrong. Please try again later.", { variant: "error" }));
        });
        setSentData(true);
      },
    });

  if (isDataSent) {
    return (
      <div className="thanksText">
        <h1 className={cls.title_success}>Thank You</h1>
        <br />
        <p>Someone from the AGM team will reach out shortly.</p>
      </div>
    );
  }

  return (
    <div className="wrapperAgentForm">
      <div className="wrapperAgentForm_content">
        <img style={{ width: "220px" }} src="/static/media/logo.107dfbad.svg" />
        {modalIsOpen ? (
          <></>
        ) : (
          <form
            id="contact-form"
            className={cls.form}
            onSubmit={(e) => {
              if (!checkBoxAgree) {
                setCheckBoxError(true);
              }
              handleSubmit(e);
            }}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <TextField
              name="name"
              label="Your Name*"
              type="text"
              variant="outlined"
              value={values.name}
              onChange={handleChange}
              className={
                Boolean(touched.name && errors.name)
                  ? classes.form_failed
                  : classes.form_success
              }
              InputProps={{ disableUnderline: true }}
              error={Boolean(touched.name && errors.name)}
              InputLabelProps={{
                style: {
                  marginLeft: "10px",
                  zIndex: 1,
                  // display: values.phone ? "none" : "block",
                  // top: values.name ? "-13px" : "-2px",
                  // fontSize: values.name ? "small" : "large",
                  // color: values.name ? "gray" : "none",
                  // transition: values.name ? "180ms" : "none",
                },
              }}
              style={{
                width: `${mobile ? "270px" : "400px"}`,
                paddingLeft: "10px",
                zIndex: 1,
              }}
            />
            <TextField
              name="phone"
              label="Phone Number*"
              type="number"
              variant="outlined"
              value={values.phone}
              onChange={(e) => {
                if (e.target.value.toString().length <= 10) {
                  handleChange(e);
                }
              }}
              className={
                Boolean(touched.phone && errors.phone)
                  ? classes.form_failed
                  : classes.form_success
              }
              InputProps={{ disableUnderline: true }}
              error={Boolean(touched.phone && errors.phone)}
              InputLabelProps={{
                style: {
                  marginLeft: "10px",

                  // display: values.phone ? "none" : "block",
                  // top: values.phone ? "-13px" : "-2px",
                  // fontSize: values.phone ? "small" : "large",
                  // color: values.phone ? "gray" : "none",
                  // transition: values.phone ? "180ms" : "none",
                },
              }}
              style={{
                width: `${mobile ? "270px" : "400px"}`,
                paddingLeft: "10px",
                zIndex: 1,
              }}
            />
            <TextField
              name="email"
              label="Email Address*"
              type="text"
              variant="outlined"
              value={values.email}
              onChange={handleChange}
              className={
                Boolean(touched.email && errors.email)
                  ? classes.form_failed
                  : classes.form_success
              }
              InputProps={{ disableUnderline: true }}
              error={Boolean(touched.email && errors.email)}
              InputLabelProps={{
                style: {
                  marginLeft: "10px",

                  // display: values.phone ? "none" : "block",
                  // top: values.email ? "-13px" : "-2px",
                  // fontSize: values.email ? "small" : "large",
                  // color: values.email ? "gray" : "none",
                  // transition: values.email ? "180ms" : "none",
                },
              }}
              style={{
                width: `${mobile ? "270px" : "400px"}`,
                paddingLeft: "10px",
                zIndex: 1,
              }}
            />
            <TextField
              name="referringEmail"
              label="Referring Agents Email*"
              type="text"
              variant="outlined"
              value={values.referringEmail}
              onChange={handleChange}
              className={
                Boolean(touched.referringEmail && errors.referringEmail)
                  ? classes.form_failed
                  : classes.form_success
              }
              InputProps={{ disableUnderline: true }}
              error={Boolean(touched.referringEmail && errors.referringEmail)}
              InputLabelProps={{
                style: {
                  marginLeft: "10px",
                  // display: values.phone ? "none" : "block",
                  // top: values.referringEmail ? "-13px" : "-2px",
                  // fontSize: values.referringEmail ? "small" : "large",
                  // color: values.referringEmail ? "gray" : "none",
                  // transition: values.referringEmail ? "180ms" : "none",
                },
              }}
              style={{
                width: `${mobile ? "270px" : "400px"}`,
                paddingLeft: "10px",
                zIndex: 1,
              }}
            />

            <FormControlLabel
              name="accept"
              type="checkbox"
              control={
                <Checkbox
                  checked={checkBoxAgree}
                  onClick={() => {
                    values.accept = !checkBoxAgree;
                    setCheckBox(!checkBoxAgree);
                    if (showCheckBoxError && !checkBoxAgree)
                      setCheckBoxError(false);
                  }}
                />
              }
              style={{
                width: `${mobile ? "270px" : "400px"}`,
                marginLeft: "10px",
                position: "absolute",
                marginTop: `${mobile ? "222px" : "195px"}`,
                zIndex: 1,
              }}
            />

            {/* marginLeft: '-366px'  */}
            <span
              style={{
                color: showCheckBoxError ? "#ff0000" : "#000",
                marginTop: "12px",
                marginLeft: `${mobile ? "30px" : "-77px"}`,
                marginBottom: "0px",
                zIndex: modalIsOpen ? 0 : 1,
              }}
            >
              I accept the{" "}
              <b
                onClick={() => setIsOpen(true)}
                style={{ textDecoration: "underline", cursor: "pointer" }}
              >
                disclosure
              </b>{" "}
              provided
            </span>

            <Button
              id="contact-form_submit-button"
              type="submit"
              className={"submit"}
              disabled={isSubmitting}
              style={{
                width: `${mobile ? "260px" : "390px"}`,
                background: `linear-gradient(90deg, #1ddee1, #43beef)`,
                color: "white",
                margin: mobile ? "10px 5px 0 15px" : "30px 5px 0 10px",
                height: `${mobile ? "40px" : "50px"}`,
              }}
            >
              Submit
            </Button>
          </form>
        )}

        <ModalInfo modalIsOpen={modalIsOpen} setIsOpen={setIsOpen} />
      </div>
    </div>
  );
}

import { Component } from 'react';
import Geocode from 'react-geocode';
import { toast } from 'react-toastify';
import axios from 'axios';
import publicIp from 'public-ip';
import logo_black from '../../assets/Icons/map-marker-black.png';
import logo from '../../assets/Icons/map-marker.png';
import Modal from './Modal';
import { calculateRegion, getLocation } from './utils';

let defaultRegion = sessionStorage.getItem('region') ?? 'gta';
let defaultLocation = sessionStorage.getItem('location') ?? 'Toronto';
let defaultCity = 'Select City';
let defaultProvince = sessionStorage.getItem('province') ?? '';
let defaultCountry = sessionStorage.getItem('country') ?? 'Canada';
let defaultPostal = null;

// set Google Maps Geocoding API for purposes of quota management. Its optional but recommended.
// TODO: Move api keys to .env file and don't commit it!
const API_KEY = 'beaca91296ad435bb9b5a231fe1234f4';
Geocode.setApiKey('AIzaSyBk30RGZX1CCvyRA79tt8OCKfapXktL3E0');
Geocode.setLanguage('en');
Geocode.setRegion('CA');

class GeoLocation extends Component {
  constructor(props) {
    super(props);
    this.openLocateModal = this.openLocateModal.bind(this);
    this.changeRegionData = this.changeRegionData.bind(this);
    this.state = {
      data: this.props.data,
      isModalOpen: false,
      location: defaultLocation,
      region: defaultRegion,
      country: defaultCountry,
      city: defaultCity,
      province: defaultProvince,
      postal: defaultPostal,
    };
  }

  openLocateModal() {
    this.toggleModal();
  }

  async checkIPGeolocate() {
    const currentIp = await publicIp.v4();
    let newCountry = defaultCountry;

    const {
      data: {
        latitude,
        longitude,
        country_name
      }
    } = await axios.get(`https://api.ipgeolocation.io/ipgeo?apiKey=${API_KEY}&ip=${currentIp}`);

    if (!latitude || !longitude) {
      console.error('geoApi error');
    }

    if (country_name) {
      newCountry = country_name;
      sessionStorage.setItem('country', country_name);
    }
    
    const newRegion = calculateRegion(latitude, longitude);
    await this.changeRegionData(newRegion);
    await this.updateCity(latitude, longitude);

    this.setState({
      data: window.$json,
      lat: latitude,
      lng: longitude,
      country: newCountry,
      region: newRegion
    });
  }

  async changeRegionData(region) {
    try {
      let json = await import('../../assets/Data/' + region + '.json');
      window.$json = json;

      if (json?.location?.[0]?.country) {
        sessionStorage.setItem('country', json.location[0].country);

        this.setState({ country: json.location[0].country });
      }

      const { location, province, city, useCity } = getLocation(region);
      sessionStorage.setItem('location', location);
      sessionStorage.setItem('region', region);

      if (useCity) json.currentLocation = location;
      this.props.setGeoData(json);

      this.setState({ region, city: location || this.state.city, province: province || this.state.province, location: location || this.state.location });
    } catch (err) {
      toast(err.message, { type: 'error' });
      console.log(err);
    }
  }

  toggleModal = () => {
    this.setState({
      header: 'Change Your City',
      isModalOpen: !this.state.isModalOpen,
    });
  };

  onUpdate = async (e) => {
    e.preventDefault();

    const value = document.querySelector('[name="postal_code"]').value;

    try {
      await this.runGeocode(value);
    } catch (err) {
      toast(err.message, { type: 'error' });
      console.log(err);
    }

    this.toggleModal();
  };

  runGeocode = async (value) => {

    if (value.length > 0) {
      sessionStorage.setItem('postal', value);
      this.setState({ postal: value });
    }

    const response = await Geocode.fromAddress(value);
    const lng = response.results[0].geometry.location.lng;
    const lat = response.results[0].geometry.location.lat;

    this.setState({ lat, lng });

    const newRegion = await calculateRegion(lat, lng);

    await this.changeRegionData(newRegion);
    await this.updateCity(lat, lng);
  };

  updateCity = async (lat, long) => {
    let newProvince;
    let newCountry;
    let newCity;

    try {
      const response = await Geocode.fromLatLng(lat, long);

      for (let i = 0; i < response.results[0].address_components.length; i++) {
        for (let j = 0; j < response.results[0].address_components[i].types.length; j++) {
          switch (response.results[0].address_components[i].types[j]) {
            case 'locality':
              newCity = response.results[0].address_components[i].long_name;
              break;
            case 'administrative_area_level_1':
              newProvince = response.results[0].address_components[i].long_name;
              break;
            case 'country':
              newCountry = response.results[0].address_components[i].long_name;
              break;
            default:
              break;
          }
        }
      }

      this.setState({ city: newCity || this.state.city,  province: newProvince || newCity || this.state.province, country: newCountry || this.state.country  });

      if (newProvince) sessionStorage.setItem('province', newProvince);
      else if (newCity) sessionStorage.setItem('province', newCity);

      this.props.updateUserLocation({
        location: [newCity, this.state.province],
        region: this.state.region,
        postal: this.state.postal,
      });
    } catch (err) {
      toast(err.message, { type: 'error' });
      console.error(err);
    }
  };

  componentDidMount() {
    let newRegion;

    if (sessionStorage.getItem('postal')) {
      let value = sessionStorage.getItem('postal');
      this.runGeocode(value);
    } else {
   
      // if (sessionStorage.getItem('province') && sessionStorage.getItem('region')) {
      //   this.changeRegionData(sessionStorage.region);
      // } else {

        this.changeRegionData(defaultRegion);

        if ('geolocation' in navigator) {
          let success = async ({ coords: { latitude, longitude } }) => {
            newRegion = calculateRegion(latitude, longitude);
            this.updateCity(latitude, longitude);
            await this.changeRegionData(newRegion);
            this.setState({
              data: window.$json,
              lat: latitude,
              lng: longitude,
            });
          };

          const error = () => this.checkIPGeolocate();

          navigator.geolocation.enableHighAccuracy = true;
          navigator.geolocation.getCurrentPosition(success, error);
        }
      // }

    }
  }

  render() {
    return (
      <>
        <div
          onClick={this.openLocateModal}
          className="message mt-5 geolocation-tab"
        >
          <img src={logo} alt="app logo"/>

          <span>{["United States", "Canada"].includes(this.state.country) 
            ? (this.state.city + ' ' + this.state.province) 
            : (this.state.country + ' ' + this.state.province)
          }</span>
        </div>

        <Modal
          show={this.state.isModalOpen}
          header={this.state.header}
          image={logo_black}
          onClose={this.toggleModal}
          country={this.state.country}
          onClick={this.onUpdate}
        />
      </>
    );
  }
}

export default GeoLocation;

import React from 'react';
// import {useTheme} from '@mui/material/styles';
// import useMediaQuery from '@mui/material/useMediaQuery';
export const ConsultationButton = ({ handeFormOpen }) => {
//   const theme = useTheme();
//   const isPhone = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <>
    <div className="phone_wrap">
        <div onClick={handeFormOpen} className="consultButton">Free Consultation</div>
    </div>

    <div style={{
          position: 'absolute',
          right: '33px',
          textAlign: 'unset',
          top: '120px',
          transform: 'scale(0.58)',
    }} className="phone_wrap">
        <div onClick={()=> window.open('https://agmrealtor.com/', '_blank')} style={{
              background: 'transparent',
              color: '#1d3c5a',
              fontWeight: '700',
              border: '2px solid #1d3c5a',
              padding: '15px 20px',
        }} className="consultButton">Want To Sell Your Home?</div>
        <span
          style={{
            position: 'absolute',
            marginTop: '27px',
            background: '#02afde',
            color: '#fff',
            padding: '3px 26px',
            fontWeight: '700',
            fontSize: '1.2em',
            userSelect: 'none',
            borderRadius: '6px',
            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.7)',
          }}
        >Click Here</span>
    </div>
    </>
  );
};


import React, { useState } from "react";
import { Button, TextField, InputLabel } from "@material-ui/core";
import Box from "@mui/material/Box";
import { makeStyles } from "@material-ui/core/styles";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import cls from "../../assets/Styles/stylesRefForm.scss";
import { green } from "@material-ui/core/colors";

const IS_DEV = window.location.hostname === "localhost";
const profesions = [
  {
    value: "Profession",
    label: "Profession*",
  },
  {
    value: "Realtor",
    label: "Realtor",
  },
  {
    value: "Mortgage Broker",
    label: "Mortgage Broker",
  },
  {
    value: "Real Estate Lawyer",
    label: "Real Estate Lawyer",
  },
];
const locations = [
  {
    value: "Location",
    label: "Location*",
  },
  {
    value: "Toronto",
    label: "Toronto",
  },
  {
    value: "Ottawa",
    label: "Ottawa",
  },
  {
    value: "Vancouver",
    label: "Vancouver",
  },
  {
    value: "Calgary",
    label: "Calgary",
  },
  {
    value: "Washington DC",
    label: "Washington DC",
  },
  {
    value: "Chicago",
    label: "Chicago",
  },
  {
    value: "San Francisco",
    label: "San Francisco",
  },
  {
    value: "Philadelphia",
    label: "Philadelphia",
  },
  {
    value: "New York",
    label: "New York",
  },
];
const salutation = [
  {
    value: "Salutation",
    label: "Salutation*",
  },
  {
    value: "Mr.",
    label: "Mr.",
  },
  {
    value: "Ms.",
    label: "Ms.",
  },
  {
    value: "Mrs.",
    label: "Mrs.",
  },
  {
    value: "Dr.",
    label: "Dr.",
  },
  {
    value: "Prof.",
    label: "Prof.",
  },
];
const useStyles = makeStyles((theme) => ({
  root: {
    width: "470px",

    "& .MuiPaper-root": {
      overflow: "visible",
      marginTop: "-10px",
    },
    "& .MuiFormControl-root": {
      marginRight: "10px",
      paddingRight: "10px",
    },
    // "& .MuiInputLabel-formControl": {
    //   top: 0,
    //   left: 0,
    //   position: "absolute",
    //   transform: "translate(0, 17px) scale(1)",
    // },

    // "& .MuiFormLabel-root.Mui-focused": {
    //   // display: "none",
    //   top: "0",
    //   color: "gray",
    //   transition: "180ms",
    //   top: 0,
    //   left: 0,
    //   position: "absolute",
    //   fontSize: "small",
    //   // transform: "translate(0, 1px) scale(1)",
    // },

    [theme.breakpoints.down("sm")]: {
      width: "270px",
    },
  },
  form_failed: {
    // border: "2px solid red",
    margin: "5px",
    color: "red",
    "& .MuiInputBase-root": {
      color: "red",
    },
  },

  form_success: {
    // border: "2px solid lightskyblue",
    // padding: "5px",
    margin: "5px",
  },
  menuStyle: {
    border: "1px solid black",
    borderRadius: "5%",
    backgroundColor: "lightgrey",
  },
}));

const setServiceValue = (service, services) =>
  !service || (!service?.value ? services[0]?.value : service.value);

export const ReferralProgram = ({ type }) => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [isDataSent, setSentData] = useState(false);
  const mobile = window.innerWidth < 650;

  const { errors, touched, values, handleChange, handleSubmit, isSubmitting } =
    useFormik({
      enableReinitialize: true,
      initialValues: {
        // salutation: "",
        name: "",
        phone: "",
        email: "",
        profession: "",
        location: "",
      },
      validationSchema: Yup.object().shape({
        // salutation: Yup.string().required(),
        name: Yup.string().required(),
        phone: Yup.string()
          .matches(/^\d+$/, "Only numbers")
          .length(10)
          .required(),
        email: Yup.string().email().required(),
        profession: Yup.string().required(),
        location: Yup.string().required(),
      }),
      onSubmit: async (values, formikHelpers) => {
        if (IS_DEV) console.log("VALUES", { ...values });

        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const requestOptions = {
          method: "POST",
          headers: myHeaders,
          redirect: "follow",
          mode: "no-cors",
          body: JSON.stringify({
            ...values,
            type,
          }),
        };

        const resp = await new Promise((resolve) => {
          fetch(
            "https://hooks.zapier.com/hooks/catch/2915270/bn5zaoz/",
            requestOptions
          )
            .then((response) => response.text())
            .then((resp) => resolve(resp))
            .catch((error) =>
              enqueueSnackbar("Something wrong. Please try again later.", {
                variant: "error",
              })
            );
        });

        if (IS_DEV) console.log("resp", resp);
        // TODO show success text here (copy from bathroom website) and close the form
        setSentData(true);
      },
    });

  if (isDataSent) {
    return (
      <div className={"thanksText"}>
        <h1 className={cls.title_success}>Thank You</h1>
        <br />
        <p>Someone from the AGM team will reach out shortly.</p>
      </div>
    );
  }

  return (
    <div className="wrapperModal">
      <div className="modalContainerRef">
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "40px 40px 20px 40px",
          }}
        >
          <img
            style={{ width: "220px" }}
            src="/static/media/logo.107dfbad.svg"
          />
        </div>
        {/* <div style={{ display: "flex", justifyContent: "center" }}>
          <hr style={{ width: mobile ? "270px" : "470px" }} />
        </div> */}

        <div className={classes.root} style={{ margin: "auto" }}>
          <div className={cls.modal_container}>
            <form
              id="contact-form"
              className={cls.form}
              onSubmit={handleSubmit}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                className={cls.inputs_col}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div>
                  {/* <TextField
                  select
                  id="salutation_select"
                  value={values.salutation}
                  onChange={(e) => {
                    if (
                      salutation.some((item) => item.value === "Salutation")
                    ) {
                      salutation.shift();
                    }
                    handleChange(e);
                  }}
                  name="salutation"
                  SelectProps={{
                    native: true,
                  }}
                  variant="standard"
                  className={
                    Boolean(touched.salutation && errors.salutation)
                      ? classes.form_failed
                      : classes.form_success
                  }
                  InputProps={{ disableUnderline: true }}
                  InputLabelProps={{
                    style: {
                      color: "black",
                      fontWeight: "bold",
                    },
                  }}
                  style={{
                    width: `${mobile ? "270px" : "150px"}`,
                    padding: "8px",
                    marginLeft: "5px",
                  }}
                >
                  {salutation.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </TextField> */}
                  <TextField
                    name="name"
                    label="Your Name*"
                    variant="outlined"
                    type="text"
                    value={values.name}
                    onChange={handleChange}
                    className={
                      Boolean(touched.name && errors.name)
                        ? classes.form_failed
                        : classes.form_success
                    }
                    InputProps={{ disableUnderline: true }}
                    error={Boolean(touched.name && errors.name)}
                    InputLabelProps={{
                      style: {
                        marginLeft: "10px",
                        // // display: values.phone ? "none" : "block",
                        // top: values.name ? "-13px" : "-2px",
                        // fontSize: values.name ? "small" : "large",
                        // color: values.name ? "gray" : "none",
                        // transition: values.name ? "180ms" : "none",
                      },
                    }}
                    style={{
                      width: `${mobile ? "270px" : "466px"}`,
                      paddingLeft: "10px",
                    }}
                  />
                </div>
                <TextField
                  name="phone"
                  variant="outlined"
                  label="Contact Number*"
                  type="number"
                  value={values.phone}
                  onChange={(e) => {
                    if (e.target.value.toString().length <= 10) {
                      handleChange(e);
                    }
                  }}
                  className={
                    Boolean(touched.phone && errors.phone)
                      ? classes.form_failed
                      : classes.form_success
                  }
                  InputProps={{ disableUnderline: true }}
                  error={Boolean(touched.phone && errors.phone)}
                  InputLabelProps={{
                    style: {
                      marginLeft: "10px",
                      // display: values.phone ? "none" : "block",
                      // top: values.phone ? "-13px" : "-2px",
                      // fontSize: values.phone ? "small" : "large",
                      // color: values.phone ? "gray" : "none",
                      // transition: values.phone ? "180ms" : "none",
                    },
                  }}
                  style={{
                    width: `${mobile ? "270px" : "466px"}`,
                    paddingLeft: "10px",
                  }}
                />
                <TextField
                  name="email"
                  label="Email Address*"
                  variant="outlined"
                  type="text"
                  value={values.email}
                  onChange={handleChange}
                  className={
                    Boolean(touched.email && errors.email)
                      ? classes.form_failed
                      : classes.form_success
                  }
                  InputProps={{ disableUnderline: true }}
                  error={Boolean(touched.email && errors.email)}
                  InputLabelProps={{
                    style: {
                      marginLeft: "10px",
                      // display: values.phone ? "none" : "block",
                      // top: values.email ? "-13px" : "-2px",
                      // fontSize: values.email ? "small" : "large",
                      // color: values.email ? "gray" : "none",
                      // transition: values.email ? "180ms" : "none",
                    },
                  }}
                  style={{
                    width: `${mobile ? "270px" : "466px"}`,
                    paddingLeft: "10px",
                  }}
                />

                <TextField
                  select
                  id="profession_select"
                  name="profession"
                  variant="outlined"
                  value={values.profession}
                  error={Boolean(touched.profession && errors.profession)}
                  onChange={(e) => {
                    if (
                      profesions.some((item) => item.value === "Profession")
                    ) {
                      profesions.shift();
                    }
                    handleChange(e);
                  }}
                  SelectProps={{
                    native: true,
                  }}
                  className={
                    Boolean(touched.profession && errors.profession)
                      ? classes.form_failed
                      : classes.form_success
                  }
                  InputLabelProps={{
                    style: { color: "red" },
                  }}
                  InputProps={{ disableUnderline: true }}
                  style={{
                    width: `${mobile ? "270px" : "466px"}`,
                    paddingLeft: "10px",
                    color: "red",
                  }}
                >
                  {profesions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </TextField>

                <TextField
                  select
                  id="location_select"
                  variant="outlined"
                  value={values.location}
                  error={Boolean(touched.location && errors.location)}
                  onChange={(e) => {
                    if (locations.some((item) => item.value === "Location")) {
                      locations.shift();
                    }
                    handleChange(e);
                  }}
                  name="location"
                  SelectProps={{
                    native: true,
                  }}
                  className={
                    Boolean(touched.location && errors.location)
                      ? classes.form_failed
                      : classes.form_success
                  }
                  InputProps={{
                    disableUnderline: true,
                  }}
                  style={{
                    width: `${mobile ? "270px" : "466px"}`,
                    paddingLeft: "10px",
                  }}
                >
                  {locations.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </TextField>
              </div>

              <Button
                id="contact-form_submit-button"
                type="submit"
                className={"submit"}
                disabled={isSubmitting}
                style={{
                  width: `${mobile ? "270px" : "446px"}`,
                  margin: mobile ? "10px 5px 0 0" : "15px 5px 0 0",
                  height: mobile ? "40px" : "50px",
                }}
              >
                Submit
              </Button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

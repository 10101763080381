import { coordinates } from './constants';

export const hav_distance = (lat1, lon1, lat2, lon2, unit) => {
  if (lat1 === lat2 && lon1 === lon2) return 0;

  const radlat1 = (Math.PI * lat1) / 180;
  const radlat2 = (Math.PI * lat2) / 180;
  const theta = lon1 - lon2;
  const radtheta = (Math.PI * theta) / 180;
  let dist =
    Math.sin(radlat1) * Math.sin(radlat2) +
    Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);

  if (dist > 1) dist = 1;

  dist = Math.acos(dist);
  dist = (dist * 180) / Math.PI;
  dist = dist * 60 * 1.1515;

  if (unit === 'K') return dist * 1.609344;

  if (unit === 'N') return dist * 0.8684;
}

export const calculateRegion = (lat, lng) => {
  let distance = [];
  const {
    ottawa,
    toronto,
    vancouver,
    calgary,
    chicago,
    boston,
    denver,
    newyork,
    philadelphia,
    sandiego,
    sanfran,
    seattle,
    washingtondc
  } = coordinates;

  distance['ottawa'] = hav_distance(
    ottawa.latitude,
    ottawa.longitude,
    lat,
    lng,
    'K'
  );

  distance['gta'] = hav_distance(
    toronto.latitude,
    toronto.longitude,
    lat,
    lng,
    'K'
  );

  distance['vancouver'] = hav_distance(
    vancouver.latitude,
    vancouver.longitude,
    lat,
    lng,
    'K'
  );

  distance['calgary'] = hav_distance(
    calgary.latitude,
    calgary.longitude,
    lat,
    lng,
    'K'
  );

  distance['chicago'] = hav_distance(
    chicago.latitude,
    chicago.longitude,
    lat,
    lng,
    'K'
  );

  distance['boston'] = hav_distance(
    boston.latitude,
    boston.longitude,
    lat,
    lng,
    'K'
  );

  distance['denver'] = hav_distance(
    denver.latitude,
    denver.longitude,
    lat,
    lng,
    'K'
  );

  distance['newyork'] = hav_distance(
    newyork.latitude,
    newyork.longitude,
    lat,
    lng,
    'K'
  );

  distance['philadelphia'] = hav_distance(
    philadelphia.latitude,
    philadelphia.longitude,
    lat,
    lng,
    'K'
  );

  distance['sandiego'] = hav_distance(
    sandiego.latitude,
    sandiego.longitude,
    lat,
    lng,
    'K'
  );

  distance['sanfran'] = hav_distance(
    sanfran.latitude,
    sanfran.longitude,
    lat,
    lng,
    'K'
  );

  distance['seattle'] = hav_distance(
    seattle.latitude,
    seattle.longitude,
    lat,
    lng,
    'K'
  );

  distance['washingtondc'] = hav_distance(
    washingtondc.latitude,
    washingtondc.longitude,
    lat,
    lng,
    'K'
  );

  let distancesValues = [
    distance['ottawa'],
    distance['gta'],
    distance['vancouver'],
    distance['calgary'],
    distance['chicago'],
    distance['boston'],
    distance['denver'],
    distance['newyork'],
    distance['philadelphia'],
    distance['sandiego'],
    distance['sanfran'],
    distance['seattle'],
    distance['washingtondc'],
  ];

  let distances = [
    'ottawa',
    'gta',
    'vancouver',
    'calgary',
    'chicago',
    'boston',
    'denver',
    'newyork',
    'philadelphia',
    'sandiego',
    'sanfran',
    'seattle',
    'washingtondc',
  ];

  let value = Math.min(...distancesValues);
  let nearest = distancesValues.indexOf(value);

  return distances[nearest];
}

export const getLocation = (region) => {
  let location, city, province, useCity;

  switch (region) {
    case 'ottawa':
      location = 'Ottawa';
      city = 'Ottawa';
      province = 'Ontario';
      break;
    case 'gta':
      location = 'GTA';
      city = 'GTA';
      province = province || 'Ontario';
      useCity = true;
      break;
    case 'calgary':
      location = 'Calgary';
      city = 'calgary';
      province = province || 'Alberta';
      break;
    case 'chigago':
      location = 'Chigago';
      city = 'chigago';
      province = 'chigago';
      break;
    case 'vancouver':
      location = 'Vancouver';
      city = 'vancouver';
      province = province || 'British Columbia';
      break;
    case 'boston':
      location = 'Boston';
      city = 'boston';
      province = '';
      break;
    case 'denver':
      location = 'Denver';
      city = 'denver';
      province = '';
      break;
    case 'newyork':
      location = 'New York';
      city = 'newyork';
      province = '';
      break;
    case 'philadelphia':
      location = 'Philadelphia';
      city = 'philadelphia';
      province = '';
      break;
    case 'sandiego':
      location = 'Sandiego';
      city = 'sandiego';
      province = '';
      break;
    case 'sanfran':
      location = 'Sanfran';
      city = 'sanfran';
      province = '';
      break;
    case 'seattle':
      location = 'Seattle';
      city = 'seattle';
      province = '';
      break;
    case 'washingtondc':
      location = 'washingtondc';
      city = 'Washington DC';
      province = '';
      break;
    default:
      location = 'GTA';
      city = 'GTA';
      province = 'Ontario';
      break;
  }

  return {
    location,
    province,
    city,
    useCity
  }
}
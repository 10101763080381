export const coordinates = {
  ottawa: {
    latitude: 45.3342894,
    longitude: -75.7164072,
  },
  toronto: {
    latitude: 43.8314437,
    longitude: -79.5223975,
  },
  vancouver: {
    latitude: 49.26388,
    longitude: -123.11792,
  },
  calgary: {
    latitude: 51.0106,
    longitude: -114.12609,
  },
  chicago: {
    latitude: 41.8781,
    longitude: -87.6298,
  },
  boston: {
    latitude: 42.3601,
    longitude: -71.0589,
  },
  denver: {
    latitude: 39.7392,
    longitude: -104.9903,
  },
  newyork: {
    latitude: 40.7128,
    longitude: -74.006,
  },
  philadelphia: {
    latitude: 39.9526,
    longitude: -75.1652,
  },
  sandiego: {
    latitude: 32.7157,
    longitude: -117.1611,
  },
  sanfran: {
    latitude: 37.7749,
    longitude: -122.4194,
  },
  seattle: {
    latitude: 47.6062,
    longitude: -122.3321,
  },
  washingtondc: {
    latitude: 38.9072,
    longitude: -77.0369,
  }
}
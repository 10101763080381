import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  IconButton,
  TextField,
  Typography,
  Box,
} from "@material-ui/core";

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography2 from '@mui/material/Typography';

import cls from "./form_dialog.module.scss";
import "./promotion.scss";
// import "./home.scss";
// import Slider from "react-slick";

import { Helmet } from "react-helmet";
import { privacyUrl } from "../../const";
import LinkItem        from "../LinkItem/LinkItem";

import Bathroom from "../../assets/images/Landing/svg/bathroom.svg";
import Kitchen from "../../assets/images/Landing/svg/kitchen.svg";
import Roofs from "../../assets/images/Landing/svg/new-roofing.svg";

import GoogleReviewLogo from "./google-review-logo.png";
import CloseIcon from "@mui/icons-material/Close";

import TestimonialsReviewerTwo from '../../assets/testimonials_images/pic-24.png';
import TestimonialsReviewerThree from '../../assets/testimonials_images/pic-03.png';
import TestimonialsReviewerFour from '../../assets/testimonials_images/4.png';
import TestimonialsReviewerFive from '../../assets/testimonials_images/pic-14.png';
import TestimonialsReviewerSix from '../../assets/testimonials_images/pic-04.png';
import TestimonialsReviewerSeven from '../../assets/testimonials_images/pic-16.png';

const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: false,
    centerMode: true,
    centerPadding: '0px',
    responsive: [
      {
        breakpoint: 769,
        settings: {
          arrows: true,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
const testimonialsItems = [
    {
      text: `I used AGM to create a legal 2 bedroom 1 washroom basement in Guelph. The team did a fantastic job, and finished the project quickly. Highly recommend for investors & people looking to add value to their homes. Project Manager Lina was terrific and ensured the process went smooth. Highly recommend 10/10.`,
      name: 'Rafi Zaman',
      photo: TestimonialsReviewerTwo,
    },
    {
      text: `Thank you to AGM Basement Renovations for redoing our basement within 18 days, from start to finish. We are really pleased with the outcome, the workmanship and our new basement completed by all the contractors involved. All of them were super to work with, including the PM Ayad Thabit. Ayad oversaw the work being done and was in contact with them and us all the time, while being professional and personable.  We would recommend AGM to everyone! Thanks to all, especially Ayad!`,
      name: 'Dawn Chandelier',
      photo: TestimonialsReviewerFive,
    },
    {
      text: `AGM has finished our bathroom renovation this past week and we are thrilled with the new beautiful bathroom. The workers were very detailed and the final bathroom is exactly as we expected. Everyone we interacted with at the office were also very responsive and all minor issues were resolved in a very timely manner!`,
      name: 'Joyce Newman',
      photo: TestimonialsReviewerThree,
    },
    {
      text: `Basement finished in 2019 and we encountered a water leak this year.  Contacted AGM and Chris Hewie Warranty Manager made arrangements to have someone come out to investigate.  It turned out the problem was not due to the work done by AGM but the team of Jason, Jeff and Bill addressed the problem and Chris  did this at no cost for us even thought it was not a warranty issue.  Truly a responsible and customer satisfaction focus.`,
      name: 'Bob Samulenok',
      photo: TestimonialsReviewerSix,
    },
    {
      text: `We recently finished our basement.  AGM was very professional and easy to work with throughout the project: from initial contract discussions with Kevin S., the Sales Rep, and from start to end of the project itself with our Project Manager.  Lina G., our Project Manager, was always readily available throughout the project to answer all our questions and kept the project on schedule.  She is very familiar with the trades and what needs to be done which helped us to better understand things as each trade came in.  She also clearly communicated next steps and scheduling each week so we knew what to expect.  Any issues were immediately addressed and corrected.  Overall, we had a very good experience with AGM.`,
      name: 'Darlene Thomas',
      photo: TestimonialsReviewerFour,
    },
    {
      text: `We are more than satisfied with our project. The project exceeded what we imagined it would be. One of the biggest things that stood out was the quality of the work and tradesmen. We felt that everyone who was working on the project had integrity. We would definitely recommend!`,
      name: 'Zaheer Abbas',
      photo: TestimonialsReviewerSeven,
    }
];

const IS_DEV = window.location.hostname === "localhost";

const additionalSliderSettings = {
    initialSlide: 0,
    autoplay: true,
};

const services = [
    {
      image: Bathroom,
      value: "Bathroom",
      title: "Bathroom Renovations",
      link: "https://bathroomrenovations.com/",
    },
    {
      image: Kitchen,
      value: "Kitchen",
      title: "Kitchen Renovations",
      link: "https://agmkitchens.com/",
    },
    {
      image: Roofs,
      value: "Roofing",
      title: "Roofing",
      link: "https://agmroofs.com/",
    }
];

export const Reviews = () => {


  return (
    <div className="wrapperModal reviews">

      <Helmet>
        <meta name="robots" content="index"/>
        <meta name="keywords" content={`>AGM Renovations Reviews`} />
        <meta name="description" content={`Click here to learn how AGM Renovations reviews every kitchen renovation project to guarantee complete customer satisfaction`} />
        <title>AGM Renovations Reviews | AGM Renovations</title>
      </Helmet>

    <IconButton
        className="closeButton"
        style={{
          width: "34px",
          height: "34px",
          position: "absolute",
          padding: "5px",
          background: "#fff",
          boxShadow: "0px 2px 3px #0000002b",
          zIndex: '10000',
          cursor: 'pointer'
        }}
        onClick={() => {
            window.location.href = window.location.origin;
        }}
      >
      <CloseIcon />
    </IconButton>

    <div className="modalContainerRef reviews">
      <Box
        style={{ 
          marginTop: '50px',
          overflow: "visible",
          marginTop: '-10px'
        }}
        maxWidth="md"
      >
      <div className={cls.modal_containerpromot}>
        <section id="promotinal" className="prompop">
          <h3 style={{ lineHeight: '46px', padding: '0 0 20px' }}> <span>AGM Renovations Reviews</span> <br/>What are you renovating?</h3>


          <section className="content">
            <div className="link_list_wrap" style={{ marginBottom: '0px' }}>
              <ul className="link_list promot">
                {services.map((itemService, index) => (
                  <LinkItem
                    key={index}
                    {...itemService}
                    onClick={()=>{}}
                    opeFormOptions={()=>{}}
                    geoData={''}
                    userLocation={''}
                  />
                ))}
              </ul>
            </div>
          </section>

          <br/>
          <section className="content">
            <div className="link_list_wrap" style={{ margin: '20px 0', lineHeight: '26px', textAlign: 'center' }}>
                When you click on one of the categories above, you should know that AGM Renovations reviews our services
                constantly to ensure they are the very best in the industry. Our commitment to detail is why AGM
                Renovations is the only renovation company that Mike Holmes of Holmes on Holmes recommended.
                <br/><br/>
                AGM Renovations reviews from customers show that we are the best renovation provider in your area for
                bathroom renovations, kitchen renovations, roofing renovations, and more. Click the area you need assistance
                with to learn more today!
            </div>
          </section>
          <br/>     <br/>
          <div style={{ cursor: 'pointer' }} onClick={()=>{ window.open('https://www.google.com/search?q=agm+renovations+google+reviews&oq=agm+renovations+google+reviews&aqs=chrome..69i57j0l4j69i60l3.3523j0j4&sourceid=chrome&ie=UTF-8#lrd=0x882b2dd8ee9962bd:0xd03d1da943a37695,1'); }}>
             <img className="google-img" style={{width: '162px'}} src={GoogleReviewLogo} alt="Google Review Logo" />
          </div>

          <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                {testimonialsItems.map((item, index) => (
                    <div key={index}>
                        <ListItem alignItems="flex-start">
                            <ListItemAvatar>
                                <Avatar alt={"item.name"} src={item.photo} />
                            </ListItemAvatar>
                            <ListItemText
                            primary={item.name}
                            secondary={
                                <React.Fragment>
                                <Typography2
                                    sx={{ display: 'inline' }}
                                    component="span"
                                    variant="body2"
                                    color="text.primary"
                                >
                                
                                </Typography2>
                                {item.text}
                                </React.Fragment>
                            }
                            />
                        </ListItem>
                        <Divider variant="inset" component="li" />
                    </div>
                ))}
          </List>
        </section>
      </div>
    </Box>
    </div>
    </div>
  );
};

import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  IconButton,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import * as Yup from "yup";
import { useFormik } from "formik";
import CloseIcon from "@mui/icons-material/Close";
import { useSnackbar } from "notistack";
import { privacyUrl } from "../../const";
import Phone from "../../assets/images/Landing/svg/phone.svg";
import cls from "./form_dialog.module.scss";

const IS_DEV = window.location.hostname === "localhost";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiPaper-root": {
      overflow: "visible",
      marginTop: '-10px'
    },
    "& .MuiFormControl-root": {
      marginRight: '10px',
      paddingRight: '10px'
    }
  },
}));
const setServiceValue = (service, services) =>
  !service || !service?.value ? services[0]?.value : service.value;

export const FormDialog = ({
  phone,
  isOpen,
  setIsOpen,
  service,
  services,
  SERVICES_ALL,
  setCompany,
  isShowPhoneNumber,
  setIsShowPhoneNumber,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const handleClose = () => {
    setIsShowPhoneNumber(false);
    setCompany(null);
    setIsOpen(false);
  };
  const classes = useStyles();
  const [isDataSent, setSentData] = useState(false);
  const [projectTypes, setProjectTypes] = useState(services);

  useEffect(() => {

    let projectTypesNew = services.flatMap((serviceData) => serviceData.optionServices || serviceData );
    projectTypesNew = projectTypesNew.map(({...serviceData}) => {
      serviceData.title = serviceData.title.replace('_', ' ');
      serviceData.title = serviceData.title.replace(' &', ' & ');
      return serviceData;
    }).filter( item => item.visible !== 'mobile' );

    if (service) {
      projectTypesNew = projectTypesNew.filter(
        (serviceData) => serviceData?.value !== service?.value
      );
      projectTypesNew.unshift(service);
    }
    
    setProjectTypes(projectTypesNew);
  }, []);

  const { errors, touched, values, handleChange, handleSubmit, isSubmitting } =
    useFormik({
      enableReinitialize: true,
      initialValues: {
        FirstName: "",
        LastName: "",
        city: "",
        Email: "",
        phone: "",
        Renovation_Type__c: setServiceValue(service, services),
      },
      validationSchema: Yup.object().shape({
        FirstName: Yup.string().required(),
        LastName: Yup.string().required(),
        city: Yup.string(),
        Email: Yup.string().email().required(),
        phone: Yup.string(),
        Renovation_Type__c: Yup.string().required(),
      }),
      onSubmit: async (values, formikHelpers) => {
        if (IS_DEV) console.log("VALUES", { ...values });

        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const requestOptions = {
          method: "POST",
          headers: myHeaders,
          redirect: "follow",
          mode: "no-cors",
          body: JSON.stringify({
            ...values,
            LeadSource: "",
            LeadOrigin: "",
            Company: "",
          }),
        };

        const resp = await new Promise((resolve) => {
          fetch(
            "https://agmrenovations.my.salesforce-sites.com/services/apexrest/createlead",
            requestOptions
          )
            .then((response) => response.text()).then((resp) => (resolve(resp)))
            .catch((error) =>
              enqueueSnackbar("Something wrong. Please try again later.", {
                variant: "error",
              })
            );
        });

        if (IS_DEV) console.log("resp", resp);

        // TODO show success text here (copy from bathroom website) and close the form
        setSentData(true);
        setTimeout(() => {
          handleClose();
        }, 4000);
      },
    });
  if (isDataSent) {
    return (
      <Dialog
        className={classes.root}
        open={isOpen}
        onClose={handleClose}
        maxWidth="md"
      >
        <IconButton
          style={{
            position: "absolute",
            top: "-17px",
            right: "-17px",
            padding: "5px",
            background: "#fff",
            boxShadow: "0px 2px 3px #0000002b",
            width: "34px",
            height: "34px"
          }}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
        <div className={cls.modal_container}>
          <h1 className={cls.title_success}>Thank You</h1>
          <br />
          <p>Someone from the AGM team will reach out shortly.</p>
        </div>
      </Dialog>
    );
  }

  return (
    <Dialog
      className={classes.root}
      open={isOpen}
      onClose={handleClose}
      maxWidth="md"
    >
      <IconButton
        id="contact-form_close-button"
        style={{
          width: "34px",
          height: "34px",
          position: "absolute",
          top: "-17px",
          right: "-17px",
          padding: "5px",
          background: "#fff",
          boxShadow: "0px 2px 3px #0000002b",
          zIndex: '10000',
          cursor: 'pointer'
        }}
        onClick={handleClose}
      >
        <CloseIcon />
      </IconButton>
      <div className={cls.modal_container}>
        <h2>Enter your info below to get started!</h2>
        {'isShowPhoneNumber' && (
          <a href={`tel:${phone}`} className={cls.phone_wrap}>
            <img src={Phone} alt="phone number" />
            <p className={cls.phone_number} style={{ color: "#19569c" }}>
              {phone}
            </p>
          </a>
        )}
        <form id="contact-form" className={cls.form} onSubmit={handleSubmit}>
          <div className={cls.inputs_col}>
            <TextField
              id="contact-form_firstname-field"
              autoFocus
              name="FirstName"
              label="First Name *"
              type="text"
              value={values.FirstName}
              onChange={handleChange}
              error={Boolean(touched.FirstName && errors.FirstName)}
            />
            <TextField
              id="contact-form_lastname-field"
              name="LastName"
              label="Last Name *"
              type="text"
              value={values.LastName}
              onChange={handleChange}
              error={Boolean(touched.LastName && errors.LastName)}
            />
            <TextField
              id="contact-form_city-field"
              name="city"
              label="City"
              type="text"
              value={values.city}
              onChange={handleChange}
              error={Boolean(touched.city && errors.city)}
            />
            <TextField
              id="contact-form_email-field"
              name="Email"
              label="Email *"
              type="text"
              value={values.Email}
              onChange={handleChange}
              error={Boolean(touched.Email && errors.Email)}
            />
            <TextField
              id="contact-form_phone-field"
              name="phone"
              label="Phone"
              type="text"
              value={values.phone}
              onChange={handleChange}
              error={Boolean(touched.phone && errors.phone)}
            />
            <TextField
              id="contact-form_project-field"
              select
              name="Renovation_Type__c"
              label="Type of project"
              value={values.Renovation_Type__c}
              // defaultValue={values.Renovation_Type__c}
              onChange={handleChange}
              error={Boolean(
                touched.Renovation_Type__c && errors.Renovation_Type__c
              )}
              SelectProps={{ native: true }}
              // SelectProps={{
              //   renderValue: (value) => value,
              // }}
            >
              {projectTypes.map((service, index) => {
                if (!service) return null;
                return (
                  <option key={index} value={service.value}>
                    {service.title}
                  </option>
                );
              })}
            </TextField>
          </div>

            <Typography variant="h6" style={{ textAlign: 'center', fontSize: '0.8rem', margin: '-25px auto 20px auto'}}>
              By clicking "continue", you agree to our Terms/Conditions &{" "}
              <a href={privacyUrl}>Privacy Policy</a>. 
              {/* We won't ever sell your information. */}
            </Typography>

          <Button id="contact-form_submit-button" type="submit" className={cls.submit} disabled={isSubmitting}>
            Continue
          </Button>
        </form>
      </div>
    </Dialog>
  );
};

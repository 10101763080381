import React, { Component, Suspense, lazy } from "react";
import { Helmet } from "react-helmet";
import { Routes, Route, Link } from "react-router-dom";
import "../../app.scss";

import LinkItem        from "../../components/LinkItem/LinkItem";
import { baseUrl } from "../../const";
import { makeStyles } from '@material-ui/core/styles';
import GeneralBanner from "./GeneralBanner";
import { createTheme, ThemeProvider } from '@mui/material/styles';


import Logo from "../../assets/images/Landing/svg/logo.svg";
import Flooring from "../../assets/images/Landing/svg/flooring.svg";
import Bathroom from "../../assets/images/Landing/svg/bathroom.svg";
import Kitchen from "../../assets/images/Landing/svg/kitchen.svg";
import Basement from "../../assets/images/Landing/svg/basement.svg";
import Painting from "../../assets/images/Landing/svg/painting.svg";
import Drywall from "../../assets/images/Landing/svg/drywall.svg";
import Windows from "../../assets/images/Landing/svg/windows-doors.svg";
import Roofs from "../../assets/images/Landing/svg/new-roofing.svg";
import Coverings from "../../assets/images/Landing/svg/coverings.svg";
import PaintingFlooring from "../../assets/images/Landing/svg/Union.svg";
import ClosetOrganizers from "../../assets/images/Landing/svg/closetorg.svg";

const theme = createTheme({
  palette: {
    primary: {
      main: '#1d3c5a',
      darker: '#1d3c5a',
    },
  },
});
const SERVICES = [
  {
    image: Basement,
    value: "Basement",
    title: "Basement Project",
    link: "https://agmbasements.com/warranty",
  },
  {
    image: Bathroom,
    value: "Bathroom",
    title: "Bathroom Project",
    link: "https://bathroomrenovations.com/warranty",
  },
  {
    image: Kitchen,
    value: "Kitchen",
    title: "Kitchen Project",
    link: "https://agmkitchens.com/warranty",
  },
  {
    image: Roofs,
    value: "Roofing",
    title: "Roofing Project",
    link: "https://agmroofs.com/warranty",
  },
  {
    image: Windows,
    value: "Windows",
    title: "Windows/Doors Project",
    link: "https://agmwindows-doors.com/warranty",
  },
  {
    image: Flooring,
    value: "Flooring",
    title: "Standalone Flooring_Project",
    link:  "https://agmflooring.com/warranty",
  },
];




export class WarrantyAdvanced extends Component {

  static propTypes = {};

  state = {
  };

  render() {

    const { step } = this.state;

    return (
      <div style={{ marginBottom: '0px' }}>

        <header style={{ padding: '50px 50px 0 50px' }} className="header">
          <Link to='/' className="logo">
            <img src={Logo} alt="logo" />
          </Link>
        </header>



        <Helmet>
          <title>AGM Renovations - Warranty Claim</title>
          <metanpm
            name="description"
            content="AGM Basements has a 4.5-star rating with over 200 Google Reviews as GTA’s top-rated basement renovations we provide 100% customer satisfaction."
          />
        </Helmet>

        <ThemeProvider theme={theme}>

                  <div style={{  
                    background: 'rgb(32,61,93)',
                    background: 'linear-gradient(180deg, rgba(32,61,93,1) 0%, rgba(33,102,144,1) 100%)',
                    width: '100%',
                    overflow: 'visible',
                    marginBottom: '60px',
                    height: '300px',
                    paddingTop: '100px'
                  }}>
                      <div className="warranty-banner">
                        <GeneralBanner title="Warranty" subTitle="Select your project type to open a warranty claim" styles={{textAlign: 'center',  color: '#fff'}} />
                      </div>
                  </div>

                <section className="content">
                  <div
                    className="link_list_wrap"
                  >
                    <ul className="link_list warranty" style={{ 
                        
                        justifyItems: 'center',
                        marginBottom: '120px'
                      }}>
                      {SERVICES.map((itemService, index) => (
                          <LinkItem
                            key={index}
                            {...itemService}
                            onClick={()=>{}}
                            opeFormOptions={()=>{}}
                            geoData={''}
                            userLocation={''}
                          />
                      ))}
                    </ul>
                  </div>
                </section>

          </ThemeProvider>




      </div>
    );
  }
}

export default WarrantyAdvanced;

import React, { useEffect } from 'react';

const Invoice = () => {

    useEffect(() => {
        window.location.replace('https://www.fastfieldwebforms.com/us/webforms/5df63897-10ce-44c8-97e7-bfee6fb04c18');
    }, []);

    return(null);
};

export default Invoice;